<!-- 协同办公 > 办公OA > 走读表单 -->
<template>
    <div class="content-main" style="margin-top: 0px;">
        <div v-if="!errorShow" class="right-panel">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <expand-filter :formData="formData" @clickBtn="clickBtn" @changeBtnFormType="changeBtnFormType"
                    :closeWidth="1250" @datePickerBlur="datePickerBlur"
                    marginBottom="10px" marginTop="10px" borderRadius="4px" class="expand-filter-wrapper"></expand-filter>
                <table-data :config="table_config" :tableData="table_data" v-loading="loadingTable"
                    backgroundHeader="#FAFBFC" :headerStyle="{ height: '50px' }" :rowStyle="{ height: '50px' }"
                    ref="table" id="table" :to-top="false">
                    <template #operate="{ data }">
                        <el-button type="text" @click="clickStudentName(data)">
                            查看详情
                        </el-button>
                    </template>
                </table-data>
                <pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow"
                    @pagination="getList()" />
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>
<script>
import {
    Loading,
    Pagination,
} from "common-local";
import TableData from "./Sub/TableData";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter";
import { mapMutations } from "vuex";
import { downloadFile, formatTreeData } from "@/libs/utils";
import RecordsList from '@/libs/pageListOptimal.js';
export default {
    name: "CampusWalkThroghFormScroll",
    components: {
        Loading,
        TableData,
        Pagination,
        ExpandFilter,
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,

            listQuery: {
                pageNum: 1,
                pageRow: 20,
                startTime: "",
                endTime: "",
                studentName: '',
                beforeStudentType: '',
                classIds: [],
                processStatus: '',
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "输入学生姓名",
                        key: "studentName",
                    },
                    {
                        type: "datePick",
                        key: "dateArr",
                        value: [],
                        dateType: "daterange",
                        format: "yyyy-MM-dd",
                        valueFormat: "yyyy-MM-dd",
                        startPlaceholder: "请选择开始时间",
                        endPlaceholder: "请选择结束时间",
                        defaultTime: ['00:00:00', '23:59:59'],
                        clearable: false,
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "当前学生类型",
                        key: "beforeStudentType",
                        list: [
                            { value: "0", label: "住宿" },
                            { value: "1", label: "走读" },
                            { value: "2", label: "半读" },
                        ]
                    },
                    {
                        placeholder: "班级",
                        type: "cascader",
                        key: 'classIds',
                        value: [],
                        organType: "",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: true,
                            value: "id",
                            emitPath: false
                        },
                        filter(data) {
                            return data.organType !== '1';
                        },
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "流程状态",
                        key: "processStatus",
                        list: [],
                        width:'180px'
                    },],
                btnList: [{
                    type: "primary",
                    text: "查询",
                    fn: "primary",
                    auth: ["attendSchool:list"],

                },
                {
                    type: "enquiry",
                    text: "重置",
                    fn: "reset",
                },
                {
                    type: "enquiry",
                    text: "导出",
                    fn: "export",
                    auth: ["attendSchool:export"],
                    loading: false
                },
                ]
            },
            table_config: {
                thead: [{
                    label: "姓名",
                    align: 'center',
                    prop: 'studentName',
                    className: 'text-spacing-reduction'
                }, {
                    label: "唯一号",
                    prop: 'onlyCode',
                    align: 'center'
                }, {
                    label: "年级",
                    prop: "gradeName",
                    align: 'center',
                    className: 'text-spacing-reduction'
                }, {
                    label: "班级",
                    prop: "className",
                    align: 'center',
                    className: 'text-spacing-reduction'
                }, {
                    label: "开始时间",
                    prop: "startTime",
                    type: 'function',
                    labelWidth: '150',
                    align: 'center',
                    callBack(row) {
                        if (row.startTime) {
                            return `${row.startTime.substr(0, 10)}`
                        } else {
                            return '-'
                        }

                    }
                }, {
                    label: "结束时间",
                    prop: "endTime",
                    align: 'center',
                    type: 'function',
                    labelWidth: '150',
                    callBack(row) {
                        if (row.endTime) {
                            return `${row.endTime.substr(0, 10)}`
                        } else {
                            return '-'
                        }

                    }
                }, {
                    label: "申请学生类型",
                    prop: "applyStudentType",
                    type: "function",
                    align: 'center',
                    labelWidth: '150',
                    callBack(row) {
                        if(row.applyStudentType){
                            switch (Number(row.applyStudentType)) {
                                case 0:
                                    return `住宿`
                                case 1:
                                    return `走读`
                                case 2:
                                    return `半读`
                                default:
                                    return '-'

                            }
                        }else{
                            return '-'
                        }

                    }
                }, {
                    label: "当前学生类型",
                    prop: "beforeStudentType",
                    type: "function",
                    align: 'center',
                    labelWidth: '150',
                    callBack(row) {
                        if(row.beforeStudentType){
                            switch (Number(row.beforeStudentType)) {
                                case 0:
                                    return `住宿`
                                case 1:
                                    return `走读`
                                case 2:
                                    return `半读`
                                default:
                                    return '-'
                            }
                        }else{
                            return '-'
                        }
                    }
                }, {
                    label: "流程状态",
                    prop: "processStatus",
                    labelWidth: "210",
                    align: 'center',
                },
                {
                    label: "申请时间",
                    prop: "createTime",
                    labelWidth: '170',
                    align: 'center',
                    type: "function",
                    callBack(row) {
                        if (row.createTime) {
                            return `${row.createTime.substr(0, 16)}`
                        } else {
                            return '-'
                        }

                    }
                },
                {
                    label: "操作",
                    labelWidth: '170',
                    align: 'center',
                    type: "slot",
                    slotName: "operate"
                },
                ],
                check: false,
                height: ""
            },
            table_data: [],
            total: 0,
        }
    },
    activated(){
        this.loadingTable = true
        setTimeout(()=>{
            this.getList()
        },300)

    },
    created() {
        this.init()
        this.getList()
    },
    mounted() {
        this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 135
    },
    computed: {
        gradeLevelName() {
            let gradeLevelName = this.$store.state.gradeLevelName
            if (gradeLevelName) {
                gradeLevelName = gradeLevelName.replace(/&lt;|</g, '-')
                return gradeLevelName.split('-')
            } else {
                return []
            }
        }
    },
    methods: {
        ...mapMutations(['setOAQueryData']),
        init() {
            let index = this.gradeLevelName.findIndex(i => i === '级部')
            if (index !== -1) {
                let idx = this.table_config.thead.findIndex(i => i.prop === 'onlyCode')
                this.table_config.thead.splice(idx + 1 + index, 0, {
                    prop: "departmentName",
                    label: "级部",
                    align: 'center',
                    className: 'text-spacing-reduction'
                })
            }
            this.setDefaultTime();
            this.getOrgList();
            this.getProcessStatusList()
        },
        /**
         * @Description: 设置默认时间
         * @DoWhat: 默认显示当月1号到当前时间（当天）
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-06-01 10:47:42
         */
        setDefaultTime() {
            let startDate = this.$moment()
                .startOf("month")
                .format("YYYY-MM-DD");
            let currentDate = this.$moment().format("YYYY-MM-DD");
            this.listQuery.startTime = startDate;
            this.listQuery.endTime = currentDate;
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [startDate, currentDate];
                }
            });
        },
        /**
         * @Description: 展开收起
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:09:53
         */
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.$nextTick(() => {
                this.table_config.height = document.body.clientHeight - document.getElementById("table").offsetTop - 135;
            })

        },

        /**
         * @Description: 查询/重置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:10:40
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                case 'reset':
                    this.formData.data.forEach((item) => {
                        console.log(item.key,'item',this.listQuery[item.key])
                        if(item.key == "classIds"){
                            this.listQuery[item.key] = []
                            item.value = []
                        }else{
                            this.listQuery[item.key] = ''
                            item.value = ''
                        }
                        // this.listQuery[item.key] = ''
                        // item.value = ''
                    })
                    this.listQuery.organType = ''
                    this.setDefaultTime()
                    this.getList(1)
                    break
                case "export":
                    this.handleExport()
                    break;
                default:
                    break;
            }
        },
        clickStudentName(data) {
            this.setOAQueryData({
                id: data.applyId, // 申请id
                dataTitle: data.formName, // 申请title
                backData: {
                    name: this.$route.name,
                    // 跳转返回到请假记录页面增加个标识
                    query: {
                        isGotoLeaveRecordAnalysis: 1
                    }
                }
            })
            //存储滚动条高度
            let recordsList = new RecordsList()
            recordsList.set(this.$route.name, {
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            this.$router.push({
                name: 'CoworkingCampusOAOAApprovalApplyDetail',
                query: {
                    activeName: '1'
                }
            })
        },
        handleExport() {
            let obj = {}
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    if (item.value && item.value.length === 2) {
                        obj.startTime = item.value[0] + ' 00:00:00'
                        obj.endTime = item.value[1] + ' 23:59:59'
                    }
                } else {
                    obj[item.key] = item.value;
                }
            });
            console.log(obj, 'obj');
            // if (obj.classId) {
            //     obj.classId = obj.classId.slice(-1).toString()
            // }
            let index = this.formData.btnList.findIndex((i) => i.fn === 'export')
            this.formData.btnList[index].loading = true
            downloadFile(
                {
                    url: "/oa/attendSchool/export",
                    method: "post",
                    form: obj,
                },
                () => {
                    this.formData.btnList[index].loading = false
                    this.$message.success('导出成功')
                },
                () => {
                    this.formData.btnList[index].loading = false
                },
            );
        },
        /**
         * @Description: 获取表格数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:11:20
         */
        getList(isPaging) {
            let data = {}
            if (isPaging) {
                this.listQuery.pageNum = 1
            }
            this.formData.data.forEach(item => {
                data[item.key] = item.value
            })
            this.loadingTable = true
            if (this.listQuery.organId) {
                this.listQuery.organId = this.listQuery.organId.slice(-1).toString()
            }
            this._fet("/oa/attendSchool/list", this.listQuery).then(res => {
                if (res.data.code === '200') {
                    console.log(res)
                    this.total = res.data.data.totalCount
                    this.table_data = res.data.data.list
                    //赋值--存储滚动条高度
                    this.$nextTick(() => {
                        let recordsList = new RecordsList()
                        if (recordsList.get(this.$route.name)) {
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                        }
                        //移除--存储滚动条高度
                        recordsList.remove(this.$route.name)
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
                this.loadingTable = false
            })
        },
        getProcessStatusList(){
            this._fet("/oa/attendSchool/processStatusList", {}).then(res => {
                if (res.data.code === '200') {
                    console.log(res)
                    //赋值--存储滚动条高度
                    this.formData.data.forEach((item)=>{
                        if(item.key == 'processStatus'){
                            item.list = res.data.data
                        }
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 处理请假日期的显示格式
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-02 20:54:11
         */
        computedTime(leaveStart, leaveEnd) {
            // 当前年份
            let curYear = this.$moment().format("YYYY")
            // 开始日期年份
            let startYear = this.$moment(leaveStart).format("YYYY")
            // 结束日期年份
            let endYear = this.$moment(leaveEnd).format("YYYY")
            let format = ''
            if (curYear === startYear && curYear && endYear) {
                format = 'MM-DD HH:mm'
            } else {
                format = 'YYYY-MM-DD HH:mm'
            }
            let startDate = this.$moment(leaveStart).format(format)
            let endDate = this.$moment(leaveEnd).format(format)
            return `${startDate} - ${endDate}`
        },
        /**
         * @Description: 获取表单下拉数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-30 15:35:32
         */
        getFormList() {
            this._fet("/oa/schoolFormInfo/permissionListByCondition", {
                status: "1",
                releaseStatus: "2",
                userPopulation: "1",
                formType: "1",
                schoolId: this.$store.state.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    const index = this.formData.data.findIndex(i => i.key === "formId");
                    if (index !== -1) {
                        this.formData.data[index].list = res.data.data.map((i) => ({
                            value: i.id,
                            label: i.formName,
                        }));
                    }
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 获取组织架构班级接口
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-30 15:43:17
         */
        getOrgList() {
            this._fet("/school/schoolOrgan/listAuthByCondition", {
                schoolId: this.$store.state.schoolId,
                jobScope: [3]
            }).then((res) => {
                if (res.data.code === '200') {
                    const index = this.formData.data.findIndex((i) => i.type === 'cascader')
                    this.formData.data[index].list =
                        formatTreeData(res.data.data.list, "id", "parentOrg")
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        // 选中年级班级
        changeCascader(data) {
            let node;
            if (data.ref.getCheckedNodes()[0]) {
                node = data.ref.getCheckedNodes()[0].data;
                this.listQuery.organType = node.organType
                console.log(node, 'node');
            } else {
                this.listQuery.organType = ''
            }
            data.ref.dropDownVisible = false;
        },
        /**
         * @Description: 筛选条件-选择时间
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-30 17:05:22
         */
        datePickerBlur() {
            let dateArr = this.formData.data.find((item) => {
                return item.key == "dateArr";
            });

            let [startTime, endTime] = dateArr.value;
            let status = this.checkTime(startTime, endTime);
            if (status) {
                this.listQuery.startTime = startTime;
                this.listQuery.endTime = endTime;
            } else {
                dateArr.value = [this.listQuery.startTime, this.listQuery.endTime]
            }
        },
        /**
         * @Description: 筛选时间校验，不能超过6个月
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-30 17:06:47
         */
        checkTime(startTime, endTime) {
            let start = new Date(startTime);
            let end = new Date(endTime);
            let status = true;
            let endFullYear = end.getFullYear();
            let startFullYear = start.getFullYear();
            let endMonth = end.getMonth();
            let startMonth = start.getMonth();

            if (endFullYear != startFullYear) {
                if (endMonth + 12 - startMonth > 6) {
                    this.$message.error("开始时间和结束时间不能超过6个月");
                    status = false;
                }

                if (endMonth + 12 - startMonth == 6) {
                    if (end.getDate() > start.getDate()) {
                        this.$message.error("开始时间和结束时间不能超过6个月");
                        status = false;
                    }
                }
            }
            if (end.getMonth() - start.getMonth() > 6) {
                this.$message.error("开始时间和结束时间不能超过6个月");
                console.log("33333");
                return (status = false);
            } else if (end.getMonth() - start.getMonth() == 6) {
                if (end.getDate() > start.getDate()) {
                    console.log("44444");
                    this.$message.error("开始时间和结束时间不能超过6个月");
                    return (status = false);
                }
            }
            return status;
        },

    }
}
</script>

<style lang="scss" scoped>
.expand-filter-wrapper {
    border-bottom: 1px solid #F2F4F7;
}

.content-wrapper {
    margin: 10px;
}

.status {
    display: flex;
    background-color: pink;

    .round {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: red;
        margin-right: 5px;
    }
}

.round {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: red;
    margin-right: 5px;
}
</style>
